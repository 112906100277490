import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './common/App.scss';
import ClientRoutes from './common/ClientRoutes';
import ClientNavbar from './components/ClientPortal/layout/Navbar';
import ClientSidebar from './components/ClientPortal/layout/Sidebar';
// import SettingsPanel from './common/shared/SettingsPanel';
import Footer from './components/ClientPortal/layout/Footer';
//import Footer from './common/shared/Footer';
import { withTranslation } from "react-i18next";

import { getLoggedInClient } from './services/ClientService'; 
import cogoToast from 'cogo-toast';
import { CLIENT_PUBLIC_URL } from './constants';
import { connect } from 'react-redux';

class ClientPanels extends Component {


  
  state = {
    cpuser : {},
    setting : {},
  }
  componentDidMount() {
    this.onRouteChanged();

    // get user data
    getLoggedInClient().then(res => {
      this.setCPUser(res.user);
      this.setTheSetting(res.setting);
      //alert(res.user.id)
    })
    .then(data => this.props.dispatchName(this.state.cpuser))
    .catch( err => {
      if(err.response.data.status == "TokenExpired"){
        localStorage.removeItem("cptoken");
        cogoToast.success(err.response.data.message, {position: 'top-right'});
        this.props.history.push(CLIENT_PUBLIC_URL+'/clientpanel/login');
      }
    });
  }

  setCPUser = (cpuser) => {
    //alert(cpuser.id)
    this.setState({ cpuser : cpuser })
  }

  setTheSetting = (setting) => {
    this.setState({ setting : setting })
  }

  render () {

    let navbarComponent = localStorage.getItem('cptoken') ? <ClientNavbar cpuser = {this.state.cpuser} setting={this.state.setting} setCPUser = {this.setCPUser} /> : '';
    let sidebarComponent = localStorage.getItem('cptoken') ? <ClientSidebar cpuser = {this.state.cpuser} setting={this.state.setting} setCPUser = {this.setCPUser} /> : '';
    // let SettingsPanelComponent = localStorage.getItem('token') && !this.state.isFullPageLayout ? <SettingsPanel/> : '';
    let footerComponent = localStorage.getItem('cptoken') ? <Footer/> : '';
    
    return (
      <div className="container-scroller">
          { navbarComponent }
          <div className="container-fluid page-body-wrapper">
         
          <div className="main-panel">
              <div className="content-wrapper">
                
              <ClientRoutes cpuser = {this.state.cpuser} setCPUser = {this.setCPUser} />
              
              </div>
             
          </div>
          
          </div>
          { footerComponent }
      </div>
      
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    const { i18n } = this.props;
    const body = document.querySelector('body');
    if(this.props.location.pathname === '/layout/RtlLayout') {
      body.classList.add('rtl');
      i18n.changeLanguage('ar');
    }
    else {
      body.classList.remove('rtl')
      i18n.changeLanguage('en');
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/clientpanel/'];
    for ( let i = 0; i < fullPageLayoutRoutes.length; i++ ) {
      // if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
      if (this.props.location.pathname.includes(fullPageLayoutRoutes[i])) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper', 'p-4');
        break;
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }

}


const getTheArray = (commonArray) => {
  return {
    type: 'CLIENT_ADMIN_NAME',
    value: commonArray
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    dispatchName: (array) => dispatch( getTheArray(array) )
  };
};

let mapStateToProps = (state) => {

  return {
    isLogged: state.isLogged,
    cpuser: state.masterUser
  };
};


const ClientPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientPanels);

export default withTranslation()(withRouter(ClientPanel));
