import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { API_BASE_URL, CLIENT_PUBLIC_URL } from '../../../constants';

class Navbar extends Component {

  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  logout = () => {
    // localStorage.clear();
    localStorage.removeItem("cptoken");
    this.props.setCPUser(null);
  }
  
  render() { 
    
    let primary_contact_name;

    if (this.props.cpuser) {
      //alert(this.props.cpuser.id);
      primary_contact_name = this.props.cpuser.primary_contact_name;
    }

    
    

    return (
      <nav className="navbar client-panel-navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
        <div className="navbar-menu-wrapper client-panel-navbar-menu-wrapper navbar-client-panel d-flex align-items-center justify-content-between">
        <a className="navbar-brand brand-logo-mini align-self-center d-lg-none" href="!#" onClick={evt =>evt.preventDefault()}><img style={{width: "50px"}} src={require("../../../assets/images/portal_logo_mini.png")} alt="logo" /></a>
          <button className="navbar-toggler navbar-toggler align-self-center d-none" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
            <i className="mdi mdi-menu"></i> 
          </button>
          <ul className="navbar-nav navbar-nav-left header-links">
            {/* <li class="nav-item active"><a class="nav-link" href="#"><i class="mdi mdi-home menu-icon"></i><span class="menu-title">Logo</span></a></li> */}
             <li className="nav-item active"><Link className="navbar-brand brand-logo-mini align-self-center" to={`${CLIENT_PUBLIC_URL}`}> <img style={{width: "250px"}} src={API_BASE_URL + "/public/assets/images/portal_logo.png"} alt="logo" /> </Link></li>
            
          </ul>
          <ul className="navbar-nav navbar-nav-right">
          {/* className={this.isPathActive('/reports') ? 'nav-item active' : 'nav-item'} */}
            <li className="nav-item active"><Link className="nav-link bg-nav-colour pl-4 pr-4 fz-16" to={`${CLIENT_PUBLIC_URL}/dashboard`}><span className="menu-title">Dashboard</span></Link></li>

            <li className="nav-item "><Link className="nav-link bg-nav-colour  pl-4 pr-4 fz-16" to={`${CLIENT_PUBLIC_URL}/project`}><span className="menu-title">Project</span></Link></li>

            <li className="nav-item "><Link className="nav-link bg-nav-colour pl-4 pr-4 fz-16" to={`${CLIENT_PUBLIC_URL}/calendar`}><span className="menu-title">Calendar</span></Link></li>

            <li className="nav-item "><Link className="nav-link fz-15" to={`${CLIENT_PUBLIC_URL}/profile/`+this.props.cpuser.id}><span className="menu-title text-muted">Welcome .<strong>{ primary_contact_name }</strong></span></Link></li>

            <li className="nav-item "><Link className="nav-link text-danger text-decoration-underline fz-15 cursor-pointer" to={'#'} onClick={this.logout}>Logout</Link></li>

            {/* <li className="nav-item  nav-profile border-0 pl-4">
              <Dropdown>
                <Dropdown.Toggle className="nav-link count-indicator p-0 toggle-arrow-hide bg-transparent">
                  <i className="mdi mdi-bell-outline"></i>
                  <span className="count bg-success">4</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="navbar-dropdown preview-list">
                  
                  <div className="dropdown-divider"></div>
                  
                  notification loops will be start here
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center" href="!#" onClick={evt =>evt.preventDefault()}>
                    <div className="preview-thumbnail">
                      <i className="mdi mdi-alert m-auto text-primary"></i>
                    </div>
                    <div className="preview-item-content py-2">
                      <h6 className="preview-subject font-weight-normal text-dark mb-1">Application Error</h6>
                      <p className="font-weight-light small-text mb-0"> Just now </p>
                    </div>
                  </Dropdown.Item>
                  notification loops will be end here

                  
                </Dropdown.Menu>
              </Dropdown>
            </li> */}
            
            {/* <li className="nav-item  nav-profile border-0">
              <Dropdown>
                <Dropdown.Toggle className="nav-link count-indicator bg-transparent">
                  <span className="profile-text">{full_name} !</span>
                  <img className="img-xs rounded-circle" src={require("../../../assets/images/faces/face8.jpg")} alt="Profile" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                  
                  <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center border-0" onClick={this.logout}>
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li> */}
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    );
  }
}

export default Navbar;
