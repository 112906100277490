import axios from "axios"; 
// axios.defaults.headers.common['AuthToken'] = localStorage.getItem('cptoken');
// axios.defaults.params = {}
axios.defaults.params['AuthToken'] = localStorage.getItem('cptoken');


/**
 * login to master portal 
 */
export const postClientLoggedIn = async (data) => {
    return await axios.post(`/client-login`, data).then((res) => {
        return res.data;
    });
}

/**
 * login to client portal
 */
// export const attemptLoggedIn = async (data) => {
//     return await axios.post(`/master-attempt-client-login`, data).then((res) => {
//         return res.data;
//     });
// }

/**
 * Get current logged in master info
 */
export const getLoggedInClient = async () => {
    return await axios.get(`/client-data`).then((res) => {
        return res.data;
    });
}


/**
 * assign Vault
 */
export const assignVault = async (id) => {
    return await axios.get(`/master-assign-vaults/` + id).then((res) => {
        return res.data;
    });
}

export const save_assign_vaults = async (data) => {
    return await axios.post(`/master-save-assign-vaults`, data).then((res) => {
        return res.data;
    });
}

//client Profile Page

export const GetClientUserdata = async (id) => { 
    return await axios.get(`get-logged-client-data/` + id).then((res) => {
        return res.data;
    });
}

export const clientImageUpdateSave = async (data) => {
    return await axios.post("/client-image-update-save", data).then((res) => {
        return res.data;
    });
}

//client Project Page
export const getProjectNameByID = async (data) => {
    return await axios.get("/project-name-by-id", data).then((res) => {
        return res.data;
    });
}


export const clientTaskStatus = async (data) => {
    return await axios.get("/client-task-status", data).then((res) => {
        return res.data;
    });
}
export const getSearchClientReport = async (data) => {    
    return await axios.post(`/get-search-client-report`, data).then((res) => {
        return res.data;
    });
}
export const getTaskDataById = async (id,statusId) => { 
    return await axios.get(`/get-task-data-by-milestone-id/${id}/${statusId}`).then((res) => {
        return res.data;
    });
}
export const getMilestonesData = async (data) => {
    return await axios.get(`/get-milestones-data`, data).then((res) => {
        return res.data;
    })
}



/**
 * Get all active client
 * @param {*} page 
 * @param {*} perPage 
 * @param {*} search 
 * @param {*} sortField 
 * @param {*} sortOrder 
 */
export const getPortalClientList = async (page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/master-portal-client?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}





/*
|--------------------------------------------------------------------------
|  ZOHO INTEGRATION Functions
|--------------------------------------------------------------------------
*/

// zoho CRM
export const getCrmApi = async (code) => {
    return await axios.get(`/get-crm-api?code=` + code).then((res) => {
        return res.data;
    });
}
/**
 * Generate Zoho API
 * @param {*} data 
 */
export const setupCrmApi = async (data) => {
    return await axios.post(`/setup-crm-api`, data).then((res) => {
        return res.data;
    });
}

// Zoho Books
/**
 * Get zoho api data
 * @param {*} zservice 
 */
export const getBooksApi = async () => {
    return await axios.get(`/get-books-api`).then((res) => {
        return res.data;
    });
}

export const setupBooksApi = async (data) => {
    return await axios.post(`/setup-books-api`, data).then((res) => {
        return res.data;
    });
}

// zoho subscriptions
export const getSubscriptionsApi = async () => {
    return await axios.get(`/get-subscriptions-api`).then((res) => {
        return res.data;
    });
}

export const setupSubscriptionsApi = async (data) => {
    return await axios.post(`/setup-subscriptions-api`, data).then((res) => {
        return res.data;
    });
}

// zoho Projects
export const getProjectsApi = async () => {
    return await axios.get(`/get-projects-api`).then((res) => {
        return res.data;
    });
}

export const setupProjectsApi = async (data) => {
    return await axios.post(`/setup-projects-api`, data).then((res) => {
        return res.data;
    });
}
// zoho Desks
export const getDesksApi = async () => {
    return await axios.get(`/get-desks-api`).then((res) => {
        return res.data;
    });
}

export const setupDesksApi = async (data) => {
    return await axios.post(`/setup-desks-api`, data).then((res) => {
        return res.data;
    });
}
// zoho Vaults
export const getVaultsApi = async () => {
    return await axios.get(`/get-vaults-api`).then((res) => {
        return res.data;
    });
}

export const setupVaultsApi = async (data) => {
    return await axios.post(`/setup-vaults-api`, data).then((res) => {
        return res.data;
    });
}
// zoho Inventory
export const getInventoryApi = async () => {
    return await axios.get(`/get-inventory-api`).then((res) => {
        return res.data;
    });
}

export const setupInventoryApi = async (data) => {
    return await axios.post(`/setup-inventory-api`, data).then((res) => {
        return res.data;
    });
}
// zoho Work Drive
export const getWorkDriveApi = async () => {
    return await axios.get(`/get-workdrive-api`).then((res) => {
        return res.data;
    });
}

export const setupWorkDriveApi = async (data) => {
    return await axios.post(`/setup-workdrive-api`, data).then((res) => {
        return res.data;
    });
}
// zoho Sign
export const getSignApi = async () => {
    return await axios.get(`/get-sign-api`).then((res) => {
        return res.data;
    });
}

export const setupSignApi = async (data) => {
    return await axios.post(`/setup-sign-api`, data).then((res) => {
        return res.data;
    });
}

// Common for zho integration
export const savePortalOption = async (data) => {
    return await axios.post(`/save-portal-option`, data).then((res) => {
        return res.data;
    });
}

export const syncZohoModuleRecords = async (service, module, page) => {
    return await axios.get(`/sync-zoho-${service}/${module}/${page}`).then((res) => {
        return res.data;
    });
}

export const setupModule = async (data) => {
    return await axios.post(`/save-portal-option`, data).then((res) => {
        return res.data;
    });
}

/*
|--------------------------------------------------------------------------
|  ZOHO Client Functions
|--------------------------------------------------------------------------
*/

/**
 * Users
 * @param {*} data 
 */

export const getUserList = async (page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/master-users?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}


// Latouts data
export const getLayoutList = async (page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/all-layouts?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}



// save User By master

export const saveUserByMaster = async (data) => {
    return await axios.post("/save-master-users", data).then((res) => {
        return res.data;
    });
}

export const insert_master_user = async (data) => {
    return await axios.post("/insert-master-users", data).then((res) => {
        return res.data;
    });
}

export const getUserById = async (id) => {
    return await axios.get(`/edit-master-users?id=` + id).then((res) => {
        return res.data;
    });
}

export const updateUser = async (data) => {
    return await axios.post(`/update-master-users`, data).then((res) => {
        return res.data;
    });
}

export const deleteUser = async (id) => {
    return await axios.get(`/delete-master-users?id=` + id).then((res) => {
        return res.data;
    });
}

export const deleteLayout = async (id) => {
    return await axios.get(`/delete-layout?id=` + id).then((res) => {
        return res.data;
    });
}



export const userAssignAccount = async (id) => {
    return await axios.get(`/userAssignAccount?id=` + id).then((res) => {
        return res.data;
    });
}

// settings
export const setting = async (id) => {
    return await axios.get(`/Setting`).then((res) => {
        return res.data;
    });
}

export const settingsUpdate = async (data) => {
    return await axios.post("/update-setting", data).then((res) => {
        return res.data;
    });
}

export const settingsLogoUpdate = async (data) => {
    return await axios.post("/setting-logo", data).then((res) => {
        return res.data;
    });
}

export const ColorSetting = async (data) => {
    return await axios.post("/setting-color", data).then((res) => {
        return res.data;
    });
}

//Twillo

export const getTwilliloSetting = async () => {
    return await axios.get(`/twilio-setup`).then((res) => {
        return res.data;
    });
}

export const getTwilliloSettingUpdate = async (data) => {
    return await axios.post("/twilio-setup", data).then((res) => {
        return res.data;
    });
}

// google setting
export const getGoogleApi = async () => {
    return await axios.get(`/google-api-setup`).then((res) => {
        return res.data;
    });
}

export const updateGoogleApi = async (data) => {
    return await axios.post("/google-api-setup", data).then((res) => {
        return res.data;
    });
}

export const updateGoogleFieldMap = async (data) => {
    return await axios.post("/google-field-setup", data).then((res) => {
        return res.data;
    });
}

// Templete setting
export const template_setting = async () => {
    return await axios.get("/template-setting").then((res) => {
        return res.data;
    });
}

export const template_activate = async (id) => {
    return await axios.get(`/activate-template/` + id).then((res) => {
        return res.data;
    });
}

export const update_template = async (data) => {
    return await axios.post("/update-template", data).then((res) => {
        return res.data;
    });
}

//User Role

export const userRole = async () => {
    return await axios.get("/user_role").then((res) => {
        return res.data;
    });
}

export const saveUserRoleByMaster = async (data) => {
    return await axios.post("/save-users_role", data).then((res) => {
        return res.data;
    });
}

export const updateUserRole = async (data) => {
    return await axios.post("/update-users_role", data).then((res) => {
        return res.data;
    });
}

export const UserModuleList = async (id) => {
    return await axios.get("/assign-user-permission/" + id).then((res) => {
        return res.data;
    });
}

export const ChangeModuleOrder = async (user_role_id, checked, module_id, action) => {
    return await axios.get("/assign-user-ChangeModuleOrder/" + user_role_id + "/" + checked + "/" + module_id + "/" + action).then((res) => {
        return res.data;
    });
}

export const ChangeForiegnKey = async (module_id, id) => {
    return await axios.get("/get-all-module-fields/" + module_id + "/" + id).then((res) => {
        return res.data;
    });
}

export const UpdateForiegnKey = async (module_name, fields, id) => {
    return await axios.get("/update-foriegn-key/" + module_name + "/" + fields + "/" + id).then((res) => {
        return res.data;
    });
}



export const permission_use_role = async () => {
    return await axios.get("/permission_use_role").then((res) => {
        return res.data;
    });
}

export const permission_use_module = async (id) => {
    return await axios.get("/permission_user_Module/" + id).then((res) => {
        return res.data;
    });
}

export const permission_detail_user_role = async (user_role_id, module) => {
    return await axios.get("/permission_details_user_Module/" + user_role_id + "/" + module).then((res) => {
        return res.data;
    });
}

export const permission_detail_get_html = async (module, user_role_id, api_name, field_id) => {
    return await axios.get("/get_only_field_permission/" + module + "/" + user_role_id + "/" + api_name + "/" + field_id).then((res) => {
        return res.data;
    });
}

export const RelatedFieldpermission_detail_get_html = async (moduleapi, fieldname) => {

    return await axios.get("/get_only_related_module_lookup_fields/" + moduleapi + "/" + fieldname).then((res) => {
        return res.data;
    });
}

export const set_only_field_permission = async (data) => {
    return await axios.post(`/set_only_field_permission`, data).then((res) => {
        return res.data;
    });
}

export const submit_layouts_fields = async (data) => {
    return await axios.post(`/submit_layouts_fields`, data).then((res) => {
        return res.data;
    });
}


export const updateuserRoleLayoutData = async (module_id, fields, fieldval) => {
    return await axios.get("/update_user_role_layout_data/" + module_id + "/" + fields + "/" + fieldval).then((res) => {
        return res.data;
    });
}

export const role_delete = async (id) => {
    return await axios.get("/role_delete/" + id).then((res) => {
        return res.data;
    });
}

export const clone_role = async (id) => {
    return await axios.get("/clone_role/" + id).then((res) => {
        return res.data;
    });
}
