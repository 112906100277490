import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../common/shared/Spinner';
import { CLIENT_PUBLIC_URL } from '../constants';

const ClientDashboard = lazy(() => import('../components/ClientPortal/Dashboard'));
const AssignVault = lazy(() => import('../components/master/vault/AssignVault'));
const Calendar = lazy(() => import('../components/ClientPortal/Calendar'));
const Profile = lazy(() => import('../components/ClientPortal/Profile'));

// zoho integration
const Project = lazy(() => import('../components/ClientPortal/Project'));
const ZohoBooks = lazy(() => import('../components/master/zoho_integration/ZohoBooks'));
const ZohoSubscriptions = lazy(() => import('../components/master/zoho_integration/ZohoSubscriptions'));
const ZohoProjects = lazy(() => import('../components/master/zoho_integration/ZohoProjects'));
const ZohoDesks = lazy(() => import('../components/master/zoho_integration/ZohoDesks'));
const ZohoVaults = lazy(() => import('../components/master/zoho_integration/ZohoVaults'));
const ZohoInventory = lazy(() => import('../components/master/zoho_integration/ZohoInventory'));
const ZohoWorkDrive = lazy(() => import('../components/master/zoho_integration/ZohoWorkDrive'));
const ZohoSign = lazy(() => import('../components/master/zoho_integration/ZohoSign'));
const ZohoSync = lazy(() => import('../components/master/zoho_integration/ZohoSync'));

// login
const ClientLogin = lazy(() => import('../components/ClientPortal/Login'));

//user 
const UserList = lazy(() => import('../components/master/user/List'));
const AssignAccount = lazy(() => import('../components/master/user/AssignAccount'));

// setting
const Setting = lazy(() => import('../components/master/settings/Setting'));
const Twillo = lazy(() => import('../components/master/settings/Twillo'));
const GoogleApiSetup = lazy(() => import('../components/master/settings/GoogleApiSetup'));

//Templete
const Templete = lazy(() => import('../components/master/settings/Templete'));

//Form Elements
const FormElement = lazy(() => import('../common/form_element/FormElement'));

//User Role
const UserRole = lazy(() => import('../components/master/user_role_permission/user_role/List'));
const ModulePermission = lazy(() => import('../components/master/user_role_permission/user_role/ModulePermission'));

//Field Permission
const Permissions = lazy(() => import('../components/master/user_role_permission/field_permission/Permissions'));
const PermissionsDetails = lazy(() => import('../components/master/user_role_permission/field_permission/PermissionsDetails'));

// Layouts view
const Layouts = lazy(() => import('../components/master/user_role_permission/layout/list'));

class ClientRoutes extends Component {
    render() {

        if (!localStorage.getItem('cptoken')) {
            return (
                <Suspense fallback={<Spinner />}>
                    <Switch>
                        <Route exact path={`${CLIENT_PUBLIC_URL}/login`} component={() => <ClientLogin cpuser={this.props.cpuser} setCPUser={this.props.setCPUser} />} ></Route>
                        <Redirect to={`${CLIENT_PUBLIC_URL}/login`} />
                    </Switch>
                </Suspense>
            );
        } else {
            return (
                <Suspense fallback={<Spinner />}>
                    <Switch>
                        <Route exact path={`${CLIENT_PUBLIC_URL}/dashboard`} component={ClientDashboard} />

                        {/* zoho integration */}
                        <Route exact path={`${CLIENT_PUBLIC_URL}/project`} component={Project} />

                        <Route exact path={`${CLIENT_PUBLIC_URL}/calendar`} component={Calendar} />
                        
                        <Route exact path={`${CLIENT_PUBLIC_URL}/profile/:id`} component={Profile} />

                        <Route exact path={`${CLIENT_PUBLIC_URL}/zoho/books`} component={ZohoBooks} />
                        <Route exact path={`${CLIENT_PUBLIC_URL}/zoho/subscriptions`} component={ZohoSubscriptions} />
                        <Route exact path={`${CLIENT_PUBLIC_URL}/zoho/projects`} component={ZohoProjects} />
                        <Route exact path={`${CLIENT_PUBLIC_URL}/zoho/desks`} component={ZohoDesks} />
                        <Route exact path={`${CLIENT_PUBLIC_URL}/zoho/vaults`} component={ZohoVaults} />
                        <Route exact path={`${CLIENT_PUBLIC_URL}/zoho/inventory`} component={ZohoInventory} />
                        <Route exact path={`${CLIENT_PUBLIC_URL}/zoho/workdrive`} component={ZohoWorkDrive} />
                        <Route exact path={`${CLIENT_PUBLIC_URL}/zoho/sign`} component={ZohoSign} />
                        {/* <Route path={`${CLIENT_PUBLIC_URL}/zoho/subscriptions/subscriptions/1`} component={ ZohoSync } /> */}
                        <Route exact path={`${CLIENT_PUBLIC_URL}/zoho/:service/:module/:page`} component={ZohoSync} />
                        {/* zoho integration end */}

                        {/* clients */}

                        <Route path={`${CLIENT_PUBLIC_URL}/users`} component={UserList} />
                        <Route path={`${CLIENT_PUBLIC_URL}/master-users-assign-account/:id`} component={AssignAccount} />
                        <Route path={`${CLIENT_PUBLIC_URL}/master-assign-vaults/:id`} component={AssignVault} />

                        {/* Settings */}
                        <Route path={`${CLIENT_PUBLIC_URL}/Setting/Set-up`} component={Setting} />
                        <Route path={`${CLIENT_PUBLIC_URL}/Setting/twilio-setup`} component={Twillo} />
                        <Route path={`${CLIENT_PUBLIC_URL}/Setting/google-api-setup`} component={GoogleApiSetup} />
                        <Route path={`${CLIENT_PUBLIC_URL}/Setting/template-setting`} component={Templete} />
                        {/* FormElement */}
                        <Route path={`${CLIENT_PUBLIC_URL}/FormElement`} component={FormElement} />
                        {/* UserRole */}
                        <Route path={`${CLIENT_PUBLIC_URL}/user-roles`} component={UserRole} />
                        <Route path={`${CLIENT_PUBLIC_URL}/assign-user-permission/:id`} component={ModulePermission} />
                        {/* Field Permission */}
                        <Route path={`${CLIENT_PUBLIC_URL}/permissions`} component={Permissions} />
                        <Route path={`${CLIENT_PUBLIC_URL}/permissions-detail/:user_role_id/:module_api_name`} component={PermissionsDetails} />

                        {/* layouts view */}
                        <Route path={`${CLIENT_PUBLIC_URL}/layouts`} component={Layouts} />

                        <Route exact path={`${CLIENT_PUBLIC_URL}/login`} component={() => <ClientLogin cpuser={this.props.cpuser} setCPUser={this.props.setCPUser} />} ></Route>
                        <Redirect to={`${CLIENT_PUBLIC_URL}/login`} />

                    </Switch>
                </Suspense>
            );
        }



    }
}

export default ClientRoutes
