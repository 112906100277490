import axios from "axios";
axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('token');

/**
 * logged in user info
 */
export const postLoggedIn = async (data) => {
    return await axios.post(`/login`, data).then((res) => {
        return res.data;
    });
}

/**
 * send email for forget password
 */
export const forgetPasswordMail = async (data) => {
    return await axios.post(`/forgetPasswordMail`, data).then((res) => {
        return res.data;
    });
} 

export const resetPassword = async (data) => {
    return await axios.post(`/resetPassword`, data).then((res) => {
        return res.data;
    });
}

/**
 * Get current logged in user info
 */
export const getLoggedInUser = async () => {
    return await axios.get(`/my-contact`).then((res) => {
        return res.data;
    });
}

/**
 * Get current permission user info
 */
 export const getCurrentUserPermission = async () => {
    return await axios.get(`/current-user-permission`).then((res) => {
        return res.data;
    });
}



/**
 * Get all active client
 * @param {*} page 
 * @param {*} perPage 
 * @param {*} search 
 * @param {*} sortField 
 * @param {*} sortOrder 
 */

//Employee/Team service
 export const getOurTeamList = async (page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/our-team?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}

export const team_delete = async (id) => {
    return await axios.get("/team_delete/" + id).then((res) => {
        return res.data;
    });
}

export const getTeamById = async (team_id) => {
    return await axios.get("/get-team-by-id/" + team_id).then((res) => {
        return res.data;
    });
}

export const getTeamMemberData = async (exceptMem = "") => {
    return await axios.get(`/get-teammember?exceptMem=${exceptMem}`).then((res) => {
        return res.data;
    });
}

export const imageUpdateSave = async (data) => {
    return await axios.post("/image-update-save", data).then((res) => {
        return res.data;
    });
}



//My Salary Section

export const getSalary = async (user_id,page, perPage = "10", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/get-salary?user_id=${user_id}&page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&delay=1`).then((res) => {
        return res.data;
    });
}

export const modifiedSalarySave = async (data) => {
    return await axios.post("/modified-salary-save", data).then((res) => {
        return res.data;
        
    });
}

export const addSalarySave = async (data) => {
    return await axios.post("/add-salary-save", data).then((res) => {
        return res.data;
        
    });
}

export const getAddSalaryField = async (id) => {
    console.log(id);
    return await axios.get(`/get_add_salary_field/${id}`).then((res) => {
        return res.data;
    });
}

export const getSalaryDetials = async (id) => {
    // console.log(id);
    return await axios.get(`/get-details-salary/${id}`).then((res) => {
        return res.data;
    });
}

export const getModifiedSalary = async (id) => {
    // console.log(id);
    return await axios.get(`/get-modified-salary/${id}`).then((res) => {
        return res.data;
    });
}
export const modifiedUpdateSalarySave = async (data) => {
    return await axios.post("/add-modified-update-salary-save", data).then((res) => {
        return res.data;
        
    });
}
//My Achievement Service

export const getAchievementData = async (user_id,page, perPage = "10", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/get-achievement?user_id=${user_id}&page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&delay=1`).then((res) => {
        return res.data;
    });
}

export const addAchievementSave = async (data) => {
    return await axios.post("/add-achievement-save", data).then((res) => {
        return res.data;
        
    });
}


export const getAchievementDetails = async (id) => {
    // console.log(id);
    return await axios.get(`/get-details-Achievement/${id}`).then((res) => {
        return res.data;
    });
}

export const achievementDelete = async (id) => {
    return await axios.get("/achievement-delete/" + id).then((res) => {
        return res.data;
    });
}

export const AchievementUpdate = async (data) => {
    return await axios.post("/achievement-update", data).then((res) => {
        return res.data;
    });
}
//My Faults Service

export const addFaultsSave = async (data) => {
    return await axios.post("/add-faults-save", data).then((res) => {
        return res.data;
        
    });
}

export const getFaultsData = async (user_id, page, perPage = "10", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/get-faults?user_id=${user_id}&page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&delay=1`).then((res) => {
        return res.data;
    });
    
}

export const getFaultsDetails = async (id) => {
    // console.log(id);
    return await axios.get(`/get-details-faults/${id}`).then((res) => {
        return res.data;
    });
}

export const FaultsUpdate = async (data) => {
    return await axios.post("/fault-update", data).then((res) => {
        return res.data;
    });
}

export const faultsDelete = async (id) => {
    return await axios.get("/faults-delete/" + id).then((res) => {
        return res.data;
    });
}

// export const getSalaryId = async (id) => {
//     return await axios.get(`/get-structure-id/${id}`).then((res) => {
//         return res.data;
//     });
// }

//Event Service

export const getEventList = async (page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/event-list?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}

export const eventSave = async (data) => {
    return await axios.post("/event-save", data).then((res) => {
        return res.data;
        
    });
}


export const getUserData = async () => {
    return await axios.get(`/get-user-data`).then((res) => {
        return res.data;
    });
}

export const getRoleData = async () => {
    return await axios.get(`/get-role-data`).then((res) => {
        return res.data;
    });
}

export const getEventByData = async (id) => {
    return await axios.get(`/event-get-data/${id}`).then((res) => {
        return res.data;
    });
}


export const eventUpdate = async (data) => {
    return await axios.post("/event-update", data).then((res) => {
        return res.data;
    });
}

export const eventDelete = async (id) => {
    return await axios.get("/event-delete/" + id).then((res) => {
        return res.data;
    });
}


export const getUpcomingEventData = async () => {
    return await axios.get(`/get-upcoming-event`).then((res) => {
        return res.data;
    });
}


export const getEventData = async () => {
    return await axios.get(`/get-event-data`).then((res) => {
        return res.data;
    });
}

export const getEventViewData = async (id) => {
    return await axios.get("/get-event-view-data/" + id).then((res) => {
        return res.data;
    });
}

export const getEventDetailsData = async (id) => {
    return await axios.get("/get-event-details-data/" + id).then((res) => {
        return res.data;
    });
}


//Leave service 
export const getLeaveList = async (page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/leave-list?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}

export const getLeaveTypeData = async () => {
    return await axios.get(`/get-leavetype`).then((res) => {
        return res.data;
    });
}

export const leaveSave = async (data) => {
    return await axios.post("/leave-save", data).then((res) => {
        return res.data;
    });
}

export const getApplicationData = async (id) => {
    return await axios.get(`/leave-applicant/${id}`).then((res) => {
        return res.data;
    });
}

export const leave_delete = async (id) => {
    return await axios.get("/leave-delete/" + id).then((res) => {
        return res.data;
    });
}


export const leaveRejectSave = async (data) => {
    return await axios.post("/leave-reject-save", data).then((res) => {
        return res.data;
    });
}

export const getLeaveByUserId = async (user_id, page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/leave-by-user-id?user_id=${user_id}&page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}
export const getLeaveByProfileId = async (page, perPage = "10", search = "", sortField = "id", sortOrder = "desc", user_id) => {
    return await axios.get(`/leave-by-profile-id?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&user_id=${user_id}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}

//Attendance service
export const getAttendanceList = async (page, in_date, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/attendance-list?page=${page}&in_date=${in_date}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}

export const attendanceSave = async (data) => {
    return await axios.post("/attendance-save", data).then((res) => {
        return res.data;
    });
}

export const getAttendanceDataById = async (id) => {
    return await axios.get(`/attendance-data/${id}`).then((res) => {
        return res.data;
    });
}

export const getLeaveCountOnDate = async (date) => {
    return await axios.get(`/leave-count-on-date/${date}`).then((res) => {
        return res.data;
    });
}

export const getAttendanceDateList = async (date) => {
    return await axios.get(`/attendance-date-data/${date}`).then((res) => {
        return res.data;
    });
}

export const attendanceUpdateSave = async (data) => {
    return await axios.post("/attendance-update", data).then((res) => {
        return res.data;
    });
}

export const attendance_delete = async (id) => {
    return await axios.get("/attendance-delete/" + id).then((res) => {
        return res.data;
    });
}

export const getAttendanceByUserId = async (user_id, page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/attendance-by-user-id?user_id=${user_id}&page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}

export const getAttendanceByProfileId = async (page, perPage = "10", search = "", sortField = "id", sortOrder = "desc", user_id) => {
    return await axios.get(`/attendance-by-profile-id?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&user_id=${user_id}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}

//Client service
export const getClientList = async (page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/client-list?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}



export const getClientEditById = async (id) => {
    return await axios.get(`/client-Edit-by-id/${id}`).then((res) => {
        return res.data;
    });
}


export const clientUpdate= async (data) => {
    return await axios.post("/client-update", data).then((res) => {
        return res.data;
    });
}

export const clientDelete = async (id) => {
    return await axios.get("/client-delete/" + id).then((res) => {
        return res.data;
    });
}


export const getClientByData = async (id) => {    
    return await axios.get(`/client-by-data/${id}`).then((res) => {
        return res.data;
    });
}

export const getProjectByList = async (page,client_id, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/get-project-by-list?page=${page}&client_id=${client_id}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}


// export const getMyTaskListByStatus = async (status, page="1", perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
//     return await axios.get(`/my-task-list-by-status/${status}?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
//         return res.data;
//     });
// }

//Project service
export const getProjectList = async (page, sortProjectWise, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    // console.log(page, sortProjectWise);
    return await axios.get(`/proj-list?page=${page}&sortProjectWise=${sortProjectWise}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}

export const clientsave = async (data) => {
    return await axios.post("/client-save", data).then((res) => {
        return res.data;
    });
}

export const getClientData = async () => {
    return await axios.get(`/get-client`).then((res) => {
        return res.data;
    });
}

export const projectSave = async (data) => {
    return await axios.post("/project-save", data).then((res) => {
        return res.data;
    });
}

export const project_delete = async (id) => {
    return await axios.get("/project-delete/" + id).then((res) => {
        return res.data;
    });
}

export const getProjectById = async (id) => {
    return await axios.get(`/project-view-by-id/${id}`).then((res) => {
        return res.data;
    });
}
export const getProjectNameID = async () => {
    return await axios.get(`/project-name-id`).then((res) => {
        return res.data;
    });
}

export const getProjectEditById = async (id) => {
    return await axios.get(`/project-Edit-by-id/${id}`).then((res) => {
        return res.data;
    });
}

export const projectMemberDelete = async (id) => {
    return await axios.get("/project-member-delete/" + id).then((res) => {
        return res.data;
    });
}

export const projectUpdateSave = async (data) => {
    return await axios.post("/project-update-data", data).then((res) => {
        return res.data;
    });
}

export const MemberSave = async (data) => {
    return await axios.post("/member-save", data).then((res) => {
        return res.data;
    });
}

export const getProjectByUserId = async (user_id, page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/project-by-user-id?user_id=${user_id}&page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}

export const getProjectByProfileId = async (page, perPage = "10", search = "", sortField = "id", sortOrder = "desc", user_id) => {
    //alert(user_id)
    return await axios.get(`/project-by-profile-id?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&user_id=${user_id}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}

export const getDocumentData = async (id) => {
    return await axios.get(`/document-Data/${id}`).then((res) => {
        // console.log(res.data);
        return res.data;
        
    });
}


// Milestones Services
export const getMilestoneList = async (page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/milestone-list?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}

export const getMilestoneListByProjectId = async (project_id, page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/milestone-list-by-project-id?project_id=${project_id}&page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}

export const getTaskByList = async (page, milestone_id, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/get-task-by-list?page=${page}&milestone_id=${milestone_id}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}

export const MilestoneSave = async (data) => {
    return await axios.post("/milestone-save", data).then((res) => {
        return res.data;
    });
}

export const MilestoneGetData = async (id) => {    
    return await axios.get(`/milestone-get-data/${id}`).then((res) => {
        return res.data;
    });
}


export const milestoneUpdateSave = async (data) => {
    return await axios.post("/milestone-update-data", data).then((res) => {
        return res.data;
    });
}

export const milestoneDeleteData = async (id) => {
    return await axios.get("milestone-delete/" + id).then((res) => {
        return res.data;
    });
}
export const MilestoneTaskGetData = async (id) => {    
    return await axios.get(`/milestone-task-by-id/${id}`).then((res) => {
        return res.data;
    });
}

//Report Activities
export const getMilestoneReport = async () => {    
    return await axios.get(`/get-milestone-report`).then((res) => {
        return res.data;
    });
}

export const getTodayTaskData = async () => {    
    return await axios.get(`/get-today-task-data`).then((res) => {
        return res.data;
    });
}

export const getSearchMilestoneReport = async (data) => {    
    return await axios.post(`/get-search-milestone-report`, data).then((res) => {
        return res.data;
    });
}

export const getSearchAllTaskData = async (data) => {    
    return await axios.post(`/get-search-task-report`, data).then((res) => {
        return res.data;
    });
}


export const getTaskByMilestoneId = async (id, statusId) => {
    return await axios.get(`/get-task-by-milestone-id/${id}/${statusId}`).then((res) => {
        return res.data;
    });
}


export const getTaskTimeLogByTaskIdReport = async (task_id) => {
    return await axios.get("/get-task-time-log-by-task-id-report/"+ task_id).then((res) => {
        return res.data;
    });
}

export const taskTimeLogUpdateSaveRevised = async (data) => {
    return await axios.post("/task-time-log-update-revised", data).then((res) => {
        return res.data;
    });
}

export const getZohoUserData = async () => {
    return await axios.get(`/get-zoho-user`).then((res) => {
        return res.data;
    });
}

export const pushMilestoneNoteZohoSave = async (data) => {
    return await axios.post("/push-milestone-note-zoho-save", data).then((res) => {
        return res.data;
    });
}

export const pushNoteZohoSave = async (data) => {
    return await axios.post("/push-note-zoho-save", data).then((res) => {
        return res.data;
    });
}

export const pushToCRMSave = async (data) => {
    return await axios.post("/push-to-crm-save", data).then((res) => {
        return res.data;
    });
}

export const getMyTaskListByStatus = async (status, page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/my-task-list-by-status/${status}?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}

//Timesheet service

export const getMyTimesheetList = async (full_date) => {
    return await axios.get(`/timesheet-list/` +full_date ).then((res) => {
        return res.data;
    });
}

export const getTimeSheetDataByUserID = async (user_id, searchDate) => {
    return await axios.get(`/timesheet-data-by-user-id/` +user_id + '/' +searchDate).then((res) => {
        return res.data;
    });
}

export const getTaskTimesheetData = async (user_id, date) => {
    return await axios.get(`/task-timesheet-data-by-user-id?user_id=${user_id}&date=${date}`).then((res) => {
        return res.data;
    });
}

export const getTaskTimeData = async (date,user_id) => {
    return await axios.get(`/get-task-time-data/` + user_id + '/' + date).then((res) => {
        return res.data;
    });
}

//Tasks service
export const getTaskList = async (page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/task-list?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}


export const getMyTaskList = async () => {
    return await axios.get(`/my-task-list`).then((res) => {
        return res.data;
    });
}

export const getMyPendingTaskList = async () => {
    return await axios.get(`/my-pending-task-list`).then((res) => {
        return res.data;
    });
}

export const getMyCompletedTaskList = async () => {
    return await axios.get(`/my-completed-task-list`).then((res) => {
        return res.data;
    });
}

export const getMyTodayTaskListByStatus = async ( page="1", perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/my-today-task-list-by-status?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}

export const getMilestoneByProjectId = async (id) => {
    return await axios.get(`/milestone-by-project-id/${id}`).then((res) => {
        return res.data;
    });
}

export const getCollaborator = async (id) => {
    return await axios.get(`/collaborator-member/${id}`).then((res) => {
        return res.data;
    });
}

export const updateTaskStatus = async (id, status) => {
    return await axios.get(`/update-task-status/${id}/${status}`).then((res) => {
        return res.data;
    });
}

export const getTaskStatus = async () => {
    return await axios.get(`/task-status`).then((res) => {
        return res.data;
    });
}

export const taskSave = async (data) => {
    return await axios.post("/task-save", data).then((res) => {
        return res.data;
    });
}

export const taskCommentSave = async (data) => {
    return await axios.post("/task-comment-save", data).then((res) => {
        return res.data;
    });
}

export const taskTimeLogSave = async (data) => {
    return await axios.post("/task-time-log-save", data).then((res) => {
        return res.data;
    });
}


export const taskTimeLogUpdateSave = async (data) => {
    return await axios.post("/task-time-log-update", data).then((res) => {
        return res.data;
    });
}

export const getTaskComments = async (task_id) => {
    return await axios.get("/get-task-comments/"+ task_id).then((res) => {
        return res.data;
    });
}

export const getTaskTimeLog = async (task_id) => {
    return await axios.get("/get-task-time-log/" +task_id).then((res) => {
        return res.data;
    });
}

export const getTaskTimeLogByTaskId = async (task_id) => {
    return await axios.get("/get-task-time-log-by-task-id/"+ task_id).then((res) => {
        return res.data;
    });
}

export const getTaskCommentByTaskId = async (task_id) => {
    return await axios.get("/get-task-comment-by-task-id/"+ task_id).then((res) => {
        return res.data;
    });
}


export const task_delete = async (id) => {
    return await axios.get("/task-delete/" + id).then((res) => {
        return res.data;
    });
}

export const getTaskDataById = async (id) => {
    return await axios.get(`/task-data/${id}`).then((res) => {
        return res.data;
    });
}

export const getTaskTimeDataById = async (id) => {
    return await axios.get(`/task-time-data/${id}`).then((res) => {
        return res.data;
    });
}
// export const taskAssignMemberName = async (id) => {
//     return await axios.get(`/task-Assign-member-name/${id}`).then((res) => {
//         return res.data;
//     });
// }

export const taskUpdateSave = async (data) => {
    return await axios.post("/task-update", data).then((res) => {
        return res.data;
    });
}

export const pospondUpdateSave = async (data) => {
    return await axios.post("/task-pospond-update", data).then((res) => {
        return res.data;
    });
}

export const getTaskByProjectId = async (project_id, page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/task-by-project-id?project_id=${project_id}&page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}


//Role service
export const getRoleList = async (page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/role-list?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}

export const roleSave = async (data) => {
    return await axios.post("/role-save", data).then((res) => {
        return res.data;
    });
}

export const getRoleByIdData = async (id) => {
    return await axios.get(`/role-data/${id}`).then((res) => {
        return res.data;
    });
}

export const getRoleInfoById = async (id) => {
    return await axios.get(`/role-data-by-id/${id}`).then((res) => {
        return res.data;
    });
}


export const roleUpdateSave = async (data) => {
    return await axios.post("/role-update", data).then((res) => {
        return res.data;
    });
}

export const role_delete = async (id) => {
    return await axios.get("/role-delete/" + id).then((res) => {
        return res.data;
    });
}

export const getFeatureGroup = async (id) => {
    return await axios.get("/get-feature-group/" + id).then((res) => {
        return res.data;
    });
}


export const rolePermissionSave = async (data) => {
    return await axios.post("/role-permission-save", data).then((res) => {
        return res.data;
    });
}


export const getTempList = async (page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/our-team?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}

//Dashboard-Clock In Service
export const clockInSave = async (status) => {
    return await axios.get(`/clock-in-save/${status}`).then((res) => {
        return res.data;
    });
}  


export const meetingWithSave = async (data) => {
    return await axios.post("/meeting-with-save", data).then((res) => {
        return res.data;
    });
}

export const getUserStatusData = async () => {
    return await axios.get(`/get-user-status`).then((res) => {
        return res.data;
    });
}

export const getStatusButtonData = async () => {
    return await axios.get(`/get-status-button`).then((res) => {
        return res.data;
    });
}

//Dashboard- Today Attendance
export const getEmployeeList = async () => {
    return await axios.get(`/our-employee`).then((res) => {
        return res.data;
    });
}

export const messageSave = async (data) => {
    return await axios.post("/message-save", data).then((res) => {
        return res.data;
    });
}

export const getLogedInUserMessage = async () => {
    return await axios.get(`/get-loged-user-message`).then((res) => {
        return res.data;
    });
}

export const getViewMessageById = async (id) => {
    return await axios.get(`/get-message-by-user-id/${id}`).then((res) => {
        return res.data;
    });
}

export const messageStatusUpdate = async (status, message_id) => {
    return await axios.get(`/message-status-update/${status}/${message_id}`).then((res) => {
        return res.data;
    });
} 


//KnowladgeBase Service

//Category Page
export const cetagorySave = async (data) => {
    return await axios.post("/cetagory-save", data).then((res) => {
        return res.data;
    });
}
export const getCetagoryList = async (page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/cetagory-list?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}


export const getCetagoryData = async (id) => {
    return await axios.get(`/get-cetagory-data/${id}`).then((res) => {
        return res.data;
    });
}

export const cetagoryUpdate = async (data) => {
    return await axios.post("/cetagory-update", data).then((res) => {
        return res.data;
    });
}

export const cetagoryDelete = async (id) => {
    return await axios.get("/cetagory-delete/" + id).then((res) => {
        return res.data;
    });
}

//ArtiCles Page
export const articlesSave = async (data) => {
    return await axios.post("/articles-save", data).then((res) => {
        return res.data;
    });
}

export const articleCategoryData = async () => {
    return await axios.get(`/article-category-data`).then((res) => {
        return res.data;
    });
}

export const getArticleList = async (page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/Article-list?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}

export const getArticleData = async (id) => {
    return await axios.get(`/get-article-data/${id}`).then((res) => {
        return res.data;
    });
}

export const articlesUpdate = async (data) => {
    return await axios.post("/articles-update", data).then((res) => {
        return res.data;
    });
}


export const ArticleDelete = async (id) => {
    return await axios.get("/article-delete/" + id).then((res) => {
        return res.data;
    });
}


//Knowledge Page

export const getKnowledgeCetagoryData = async () => {
    return await axios.get(`/knowledge-cetagory-data`).then((res) => {
        return res.data;
    });
}

// export const categorySearchData = async (searchCatData) => {
//     return await axios.get(`/category-search-data/` + searchCatData).then((res) => {
//         return res.data;
//     });
// }

export const categorySearchData = async (data) => {
    return await axios.get(`/category-search-data?search=`+ data).then((res) => {
        return res.data;
    });
}


//Category Details Page

export const artData = async (id) => {
    return await axios.get(`/art-list/` + id).then((res) => {
        return res.data;
    });
}

export const articleSearchData = async (id,data) => {
    return await axios.get(`/art-search-list/`+ id +`?search=`+ data).then((res) => {
        return res.data;
    });
}

//Article Details Page cat-all-art-list/{id}

export const catAllArtData = async (id) => {
    return await axios.get(`/cat-all-art-list/` + id).then((res) => {
        return res.data;
    });
}

export const articleDesSearchData = async (id,data) => {
    return await axios.get(`/art-des-search-list/`+ id +`?search=`+ data).then((res) => {
        return res.data;
    });
}

/**
 * updateContactInfo
 * update contact info tab data to local & crm
 * @param {*} data 
 */
export const updateContactInfo = async (data) => {
    return await axios.post("/save-contact", data).then((res) => {
        return res.data;
    });
}
/**
 * sendVerificationRequest
 * Passed two factor fields data to create verification code & send
 * @param {*} data 
 */
export const sendVerificationRequest = async (data) => {
    return await axios.post("/send-verification-code", data).then((res) => {
        return res.data;
    });
}
export const sendVerificationCallRequest = async (data) => {
    return await axios.post("/twilio-voice", data).then((res) => {
        return res.data;
    });
}
/**
 * submitVerificationCode
 * Passed two factor verification code to check
 * @param {*} data 
 */
export const submitVerificationCode = async (data) => {
    return await axios.post("/submit-verification-code", data).then((res) => {
        return res.data;
    });
}

export const addContactNote = async (data) => {
    return await axios.post("/save-contact-note", data).then((res) => {
        return res.data;
    });

}

// save ContactMeeting
export const addContactMeeting = async (data) => {
    return await axios.post("/save-contact-meeting", data).then((res) => {
        return res.data;
    });
}

export const updateContactMeeting = async (data) => {
    return await axios.post("/update-contact-meeting", data).then((res) => {
        return res.data;
    });
}

export const deleteContactMeeting = async (id) => {
    return await axios.get("/delete-contact-meeting?module_id="+id+"").then((res) => {
        return res.data;
    });
}

/**
 * Get all contact
 */
export const getContactList = async (page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/zc-contacts?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}
/**
 * getActivityList
 * Get all activity of logged in user
 * @param {*} page 
 * @param {*} perPage 
 * @param {*} search 
 * @param {*} sortField 
 * @param {*} sortOrder 
 */
export const getActivityList = async (page, perPage = "10", search = "", sortField = "id", sortOrder = "desc") => {
    return await axios.get(`/my-activity?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&delay=1`).then((res) => {
        return res.data;
    });
}


// save ContactCall
export const addContactCall = async (data) => {
    return await axios.post("/save-contact-call", data).then((res) => {
        return res.data;
    });
}

export const updateContactCall = async (data) => {
    return await axios.post("/update-contact-call", data).then((res) => {
        return res.data;
    });
} 

export const deleteContactCall = async (module_id) => {
    return await axios.get("/delete-contact-call/"+module_id+"").then((res) => {
        return res.data;
    });
} 

// save ContactCTasks
export const addContactTasks = async (data) => {
    return await axios.post("/save-contact-task", data).then((res) => {
        return res.data;
    });
}

export const updateContactTasks = async (data) => {
    return await axios.post("/update-contact-task", data).then((res) => {
        return res.data;
    });
}

export const deleteContactTask = async (id) => {
    return await axios.get("/delete-contact-task/"+id+"").then((res) => {
        return res.data;
    });
}

//get all my-projects
export const getMyProjectList = async (page, perPage = "10", search = "", sortField = "id", sortOrder = "desc" , job_status="" ) => {
    return await axios.get(`/my-projects?page=${page}&per_page=${perPage}&sort_field=${sortField}&sort_order=${sortOrder}&search=${search}&job_status=${job_status}&delay=1`).then((res) => {
        return res.data;
    });
}

//get all my-projects status
export const getMyProjectListStatus = async () => {
    return await axios.get(`/my-projects-status`).then((res) => {
        return res.data;
    });
}

export const getMyProjectDetail = async (id) => {
    return await axios.get(`/my-projects-details/${id}`).then((res) => {
        return res.data;
    });
}

export const uploadProjectAttacment = async (data) => {
    return await axios.post("/uploadProjectAttacment", data).then((res) => {
        return res.data;
    });
}

//HandleSubmit
export const FormElements = async (data) => {
    return await axios.post("/FormElement", data).then((res) => {
        return res.data;
    });
}

export const selectOptions = async (page,serach) => {
    return await axios.get(`/selectOptions/${page}/${serach}`).then((res) => {
        return res.data;
    });
}

export const changeNewPassword = async (data) => { 
    return await axios.post("/changePassword", data).then((res) => {
        return res.data;
    });
}


export const passwordChangeSubmit = async (data) => {
    return await axios.post("/password-change-submit", data).then((res) => {
        return res.data;
    });
}
