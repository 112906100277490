import React, { Component } from 'react';
import { Link, useParams, withRouter, useHistory } from 'react-router-dom';

import { Collapse } from 'react-bootstrap';
import { PUBLIC_URL } from '../../constants';
import { connect } from 'react-redux';
import { getCurrentUserPermission } from '../../services/CommonService';

class Sidebars extends Component {
  state = {
    otherBool: false,
    feature_id:[],
    is_admin: 0
  };
  

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
    
  }


  onRouteChanged() {
    if (parseInt(this.props.templete.id) === 1 || parseInt(this.props.templete.id) === 2) {
      document.querySelector('#sidebar').classList.remove('active');
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
    }

    const dropdownPaths = [
    //   {path:'/apps', state: 'appsMenuOpen'},
    //   {path:'/basic-ui', state: 'basicUiMenuOpen'},
    //   {path:'/advanced-ui', state: 'advancedUiMenuOpen'},
    //   {path:'/form-elements', state: 'formElementsMenuOpen'},
    //   {path:'/tables', state: 'tablesMenuOpen'},
    //   {path:'/maps', state: 'mapsMenuOpen'},
    //   {path:'/icons', state: 'iconsMenuOpen'},
    //   {path:'/charts', state: 'chartsMenuOpen'},
    //   {path:'/user-pages', state: 'userPagesMenuOpen'},
    //   {path:'/error-pages', state: 'errorPagesMenuOpen'},
    //   {path:'/general-pages', state: 'generalPagesMenuOpen'},
      {path:'/hrm', state: 'adminHrmPagesMenuOpen'},
      {path:'/help', state: 'helpAndSupport'},
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({[obj.state] : true})
      }
    }));

  }



  render() {
    
    const dropdownAction = (e, rownum1) => {
      let defel = document.getElementById("drop_option")
      defel.classList.add("disable-color")

      if (this.state.otherBool == false) this.setState({ otherBool: true })
      if (this.state.otherBool == true) this.setState({ otherBool: false })
    }


    return (
      <>
        { this.state.is_admin=='1' || (this.props.feature_id && this.props.feature_id.includes("mmp001")) ?
        (
        <li className={this.isPathActive('/dashboard') ? 'nav-item active home-mt' : 'nav-item home-mt'}>
          <Link className="nav-link" to={`${PUBLIC_URL}/dashboard`}>
            <i className="mdi mdi-desktop-mac menu-icon"></i>
            <span className="menu-title">Dashboard </span>
          </Link>
        </li>
        )
        :'' }
    
        {/* <li className={this.isPathActive('/my-profile') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to={`${PUBLIC_URL}/my-profile`}>
            <i className="mdi mdi-account menu-icon"></i>
            <span className="menu-title">Profile</span>
          </Link>
        </li> */}

        { this.state.is_admin=='1' || (this.props.feature_id && this.props.feature_id.includes("mmp002")) ?
        (
        <li className={this.isPathActive('/my-task') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to={`${PUBLIC_URL}/my-task`}>
            <i className="fa fa-tasks menu-icon"></i>
            <span className="menu-title">My Task</span>
          </Link>
        </li>
        )
        :'' }

        { this.state.is_admin=='1' || (this.props.feature_id && this.props.feature_id.includes("mmp003")) ?
        (
        <li className={(this.isPathActive('/projects') || this.isPathActive('/project/view/')|| this.isPathActive('/add-project')|| this.isPathActive('/edit-project') ) ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to={`${PUBLIC_URL}/projects`}>
            <i className="fa fa-th-list menu-icon"></i>
            <span className="menu-title">Projects</span>
          </Link>
        </li>
        )
        :'' }

        {/* <li className={this.isPathActive('/add-project') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to={`${PUBLIC_URL}/add-project`}>
            <i className="mdi mdi-account menu-icon"></i>
            <span className="menu-title">Add Project</span>
          </Link>
        </li> */}

        
        {/* { this.state.is_admin=='1' || (this.props.feature_id && this.props.feature_id.includes("mmp004")) ?
        (
        <li className={this.isPathActive('/report-copy') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to={`${PUBLIC_URL}/report-copy`}>
            <i className="mdi mdi-elevation-rise menu-icon"></i>
            <span className="menu-title">Reports </span>
          </Link>
        </li>
         )
          : ''} */}
        
        { this.state.is_admin=='1' || (this.props.feature_id && this.props.feature_id.includes("mmp004")) ?
        (
        <li className={this.isPathActive('/reports') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to={`${PUBLIC_URL}/reports`}>
            <i className="mdi mdi-elevation-rise menu-icon"></i>
            <span className="menu-title">Reports </span>
          </Link>
          </li>
            )
          : ''}

        { this.state.is_admin=='1' || (this.props.feature_id && this.props.feature_id.includes("mmp005")) ?
        (
        <li className={this.isPathActive('/timesheet') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to={`${PUBLIC_URL}/timesheet`}>
            <i className="mdi mdi-clock menu-icon"></i>
            <span className="menu-title">Timesheet</span>
          </Link>
        </li>
        )
        :'' }

       
        <li className={ this.isPathActive('/hrm') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.adminHrmPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('adminHrmPagesMenuOpen') } data-toggle="collapse">
              <i className="mdi mdi-account-settings menu-icon"></i>
              <span className="menu-title">Admin & HRM</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.adminHrmPagesMenuOpen }>
              <ul className="nav flex-column sub-menu">
                { this.state.is_admin=='1' || (this.props.feature_id && this.props.feature_id.includes("a&h001")) ?
                (
                <li className="nav-item"> <Link className= { this.isPathActive('/hrm/our-team')||this.isPathActive('/hrm/addemployee') ? 'nav-link active' : 'nav-link' } to="/hrm/our-team">Our Team</Link></li>
                )
                :'' }
                {/* <li className="nav-item"> <Link className={ this.isPathActive('/hrm/addemployee') ? 'nav-link active' : 'nav-link' } to="/hrm/addemployee">Add Employee</Link></li> */}
                { this.state.is_admin=='1' || (this.props.feature_id && this.props.feature_id.includes("a&h002")) ?
                (
                <li className="nav-item"> <Link className={ this.isPathActive('/hrm/attendance') ? 'nav-link active' : 'nav-link' } to="/hrm/attendance">Attendance</Link></li>
                )
                :'' }
                { this.state.is_admin=='1' || (this.props.feature_id && this.props.feature_id.includes("a&h003")) ?
                (
                <li className="nav-item"> <Link className={ this.isPathActive('/hrm/leave') ? 'nav-link active' : 'nav-link' } to="/hrm/leave">Leave</Link></li>
                )
                :'' }
                { this.state.is_admin=='1' || (this.props.feature_id && this.props.feature_id.includes("a&h004")) ?
                (
                <li className="nav-item"> <Link className={ this.isPathActive('/hrm/role')||this.isPathActive('/hrm/employeerolepermission') ? 'nav-link active' : 'nav-link' } to="/hrm/role">Role</Link></li>
                )
                :'' }
                { this.state.is_admin=='1' || (this.props.feature_id && this.props.feature_id.includes("a&h005")) ?
                (
                <li className="nav-item"> <Link className={ this.isPathActive('/hrm/events') ? 'nav-link active' : 'nav-link' } to="/hrm/events">Events</Link></li>
                )
                :'' }
                {/* <li className="nav-item"> <Link className={ this.isPathActive('/hrm/employeerolepermission') ? 'nav-link active' : 'nav-link' } to="/hrm/employeerolepermission">Employee Role Permission</Link></li> */}
              </ul>
            </Collapse>
        </li>  
        

        { this.state.is_admin=='1' || (this.props.feature_id && this.props.feature_id.includes("mmp009")) ?
        (
          <li className={this.isPathActive('/client') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to={`${PUBLIC_URL}/client`}>
            <i className="mdi mdi-account menu-icon"></i>
            <span className="menu-title">Client</span>
          </Link>
          </li>
          )
          :'' }

          { this.state.is_admin=='1' || (this.props.feature_id && this.props.feature_id.includes("mmp007")) ?
          (
          <li className={this.isPathActive('/calendar') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to={`${PUBLIC_URL}/calendar`}>
            <i className="mdi mdi-calendar menu-icon"></i>
            <span className="menu-title">Calendar</span>
          </Link>
        </li>
        )
        :'' }
        
        { this.state.is_admin=='1' || (this.props.feature_id && this.props.feature_id.includes("mmp008")) ?
        (
        <li className={ this.isPathActive('/help') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.helpAndSupport ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('helpAndSupport') } data-toggle="collapse">
              <i className="mdi mdi-help-circle-outline menu-icon"></i>
              <span className="menu-title">Help & Support</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={ this.state.helpAndSupport }>
              <ul className="nav flex-column sub-menu">
               
                <li className="nav-item"> <Link className={ this.isPathActive('/help/KnowledgeBase') ? 'nav-link active' : 'nav-link' } to="/help/KnowledgeBase">Knowledge Base</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/help/Articles') ? 'nav-link active' : 'nav-link' } to="/help/Articles">Articles</Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/help/Categories') ? 'nav-link active' : 'nav-link' } to="/help/Categories">Categories</Link></li>
                
              </ul>
            </Collapse>
        </li>
        )
        :'' }

          {/* <li className={this.isPathActive('/knowledge-base') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to={`${PUBLIC_URL}/knowledge-base`}>
            <i className="mdi mdi-account menu-icon"></i>
            <span className="menu-title">Knowledge Base</span>
          </Link>
          </li> */}


        {/* <li className={this.isPathActive('/Client-Portal') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to={`${PUBLIC_URL}/Client-Portal`}>
            <i className="mdi mdi-account menu-icon"></i>
            <span className="menu-title">Client Portal</span>
          </Link>
        </li> */}

        <li className={this.isPathActive('/empty-page') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to={`${PUBLIC_URL}/empty-page`}>
            <i className="mdi mdi-account menu-icon"></i>
            <span className="menu-title">Empty Page</span>
          </Link>
        </li>

        
        {/* <li className={this.isPathActive('/test-page') ? 'nav-item active' : 'nav-item'}>
        <Link className="nav-link" to={`${PUBLIC_URL}/test-page`}>
          <i className="mdi mdi-account menu-icon"></i>
          <span className="menu-title">Test Page</span>
        </Link>
        </li> */}
          

        
        



      </>

    );

  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  
 

}


let mapStateToProps = (state) => {

  return {
    isLogged: state.isLogged,
    user: state.user_contact,
    logged_contact: state.logged_contact,
    templete: state.templete,
    setting: state.setting,
    zoho: state.zoho,
    feature_id: state.feature_id
  };
};

const Sidebar = connect(
  mapStateToProps
  // mapDispatchToProps
)(Sidebars);


export default withRouter(Sidebar);