export const PUBLIC_URL = "";
export const MASTER_PUBLIC_URL = "/master";
export const CLIENT_PUBLIC_URL = "/clientpanel";
export const MASTER_ADMIN_PUBLIC_URL = "/admin";
// export const API_BASE_URL = "http://localhost/projects/sa-project-manager/local";
//export const API_BASE_URL = "http://192.168.1.4:8080/projects/sa-project-mang/local";
export const API_BASE_URL = "https://office.softanis.com/local";
// export const API_BASE_URL = "https://softanis.com/officedemo/local";
// export const API_BASE_URL = "http://localhost/sa-project-manager/local";

 //export const API_BASE_URL = "http://softanis.net/project_manager/local";
