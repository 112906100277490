import React, { Component } from 'react';
import { withRouter, Link, Redirect } from 'react-router-dom';
import './common/App.scss';
import AppRoutes from './common/AppRoutes';
import Navbar from './common/shared/Navbar';
import Sidebar from './common/shared/Sidebar';
// import SettingsPanel from './common/shared/SettingsPanel';
import Footer from './common/shared/Footer';
// import Login from './components/auth/Login';
import { withTranslation } from "react-i18next";
import { Dropdown } from 'react-bootstrap';

//important
import axios from 'axios';
import { connect } from 'react-redux';
import { PUBLIC_URL, API_BASE_URL } from './constants';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');



class UserPanels extends Component {

  state = {
    user: {},
    logged_contact: {}
  }

  logout = () => {
    // localStorage.clear();
    localStorage.removeItem("token");
    this.props.setUser(null);
    this.props.setFeatureId([]);
    this.props.setLoggedContact(null);
    this.props.dispatcTemplete([]);
    return <Redirect to="/login"></Redirect>
  }

  componentDidMount() {
    this.onRouteChanged();

    // get user data
    axios.get('/user')
      .then((response) => {
        //console.log(response.data.feature_id)
        this.setUser(response.data.user);
        this.setFeatureId(response.data.feature_id);
        this.setLoggedContact(response.data.logged_contact);
        this.setTempleteSetting(response.data.template_setting);
        this.setSetting(response.data.setting);
        this.setZhSubscriptionOption(response.data.z_option);
        this.setModule_listName(response.data.module_listName);
        this.setHasActivitis(response.data.hasActivitis);
      })
      .then(data => this.props.dispatchName(this.state.logged_contact))
      .then(data => this.props.dispatcUser(this.state.user))
      .then(data => this.props.dispatchFeatureId(this.state.feature_id))
      .then(data => this.props.dispatcTemplete(this.state.template_setting))
      .then(data => this.props.dispatcSetting(this.state.setting))
      .then(data => this.props.dispatcZh_option(this.state.z_option))
      .catch((err) => {
        
        if (err.response && err.response.data.message == "Unauthenticated.") {
          localStorage.removeItem("token");

          // if (!window.location.href.includes("/password-reset/")) {
          //   this.props.history.push(PUBLIC_URL + '/login');
          // }
          if (!window.location.href.includes("/password-reset/") && !window.location.href.includes("/forget-password") && !window.location.href.includes("/register")) {
            this.props.history.push(PUBLIC_URL + '/login');
          }
        }
      });
  }

  setUser = (user) => {
    //alert(user.id)
    this.setState({ user: user })
  }

  setFeatureId = (feature_id) => {
    //alert(feature_id)
    this.setState({ feature_id: feature_id })
  }

  setLoggedContact = (logged_contact) => {
    this.setState({ logged_contact: logged_contact })
  }

  setModule_listName = (module_listName) => {
    this.setState({ module_listName: module_listName })
  }
  setTempleteSetting = (template_setting) => {
    this.setState({ template_setting: template_setting })
  }
  setSetting = (setting) => {
    this.setState({ setting: setting })
  }

  setZhSubscriptionOption = (z_option) => {
    this.setState({ z_option: z_option })
  }

  setHasActivitis = (hasActivitis) => {
    this.setState({ hasActivitis: hasActivitis })
  }

   
    

  render() {

    let navbarComponent = localStorage.getItem('token') ? <Navbar user={this.props.user} setting={this.state.setting} setUser={this.setUser} logged_contact={this.props.logged_contact} setFeatureId={this.props.setFeatureId} setLoggedContact={this.setLoggedContact} /> : '';
    let sidebarComponent = localStorage.getItem('token') ? <Sidebar hasActivitis={this.state.hasActivitis} module_listName={this.state.module_listName} user={this.props.user} feature_id={this.props.feature_id} setUser={this.setUser} logged_contact={this.props.logged_contact} setLoggedContact={this.setLoggedContact} /> : '';
    // let SettingsPanelComponent = localStorage.getItem('token') && !this.state.isFullPageLayout ? <SettingsPanel/> : '';
    let footerComponent = localStorage.getItem('token') ? <Footer /> : '';

    let full_name = this.props.user.first_name +" "+this.props.user.last_name;

    // window.onscroll = function () { myFunction() };

    // let navbar = document.getElementById("sidebar");
    

    // function myFunction() {

    //    console.log('dhukese................')

    //         if (navbar != null && navbar != undefined && navbar != '') {
    //             if (window.pageYOffset >= 10) {
    //               navbar.classList.add("sticky")
    //               console.log('dhukese................111')
                  
    //             } else {
    //               navbar.classList.remove("sticky");
    //               console.log('dhukese................2222')
    //             }
    //         }

    // }

    if (this.props.templete.template_api_name === "template_one" || this.props.templete.template_api_name === "template_two") {
      let class_name = "templete_" + parseInt(this.props.templete.id) + " container-scroller";
      return (

        <div className={class_name}>
          { navbarComponent}
          <div className="container-fluid page-body-wrapper ">
            <nav className="sidebar sidebar-offcanvas position-fixed-tp12" id="sidebar">
              <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
                <Link className="sidebar-brand brand-logo" to={`${PUBLIC_URL}`}> <img src={API_BASE_URL + "/public/assets/images/portal_logo.png"} alt="logo" /></Link>
                <Link className="sidebar-brand brand-logo-mini pt-3" to={`${PUBLIC_URL}`}> <img src={API_BASE_URL + "/public/assets/images/portal_logo_mini.png"} alt="logo" /></Link>
              </div>
              <ul className="nav"  >
                <li className="nav-item nav-profile not-navigation-link">
                  <div className="nav-link">
                    <Dropdown>
                      <Dropdown.Toggle className="nav-link user-switch-dropdown-toggler p-0 toggle-arrow-hide bg-transparent border-0 w-100">
                        <div className="d-flex justify-content-between align-items-start">
                          <div className="profile-image">
                            <img src={`${API_BASE_URL}/public/profile_image/`+(this.props.user.image!=null?this.props.user.image:'default_profile.jpg')} alt="profile" />
                          </div>
                          <div className="text-left">
                            <p className="profile-name">   </p>
                            <small className="designation text-muted text-small"> {full_name} </small>
                            <span className="status-indicator online"></span>
                          </div>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="preview-list navbar-dropdown">

                        <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center text-small" as={Link} to={`${PUBLIC_URL}/my-profile`} >
                          Manage Accounts
                          </Dropdown.Item>

                        <Dropdown.Item className="dropdown-item preview-item d-flex align-items-center text-small" onClick={this.logout}  >
                          Sign Out
                          </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </li>

                {sidebarComponent}

              </ul>
            </nav>
            <div className="main-panel">
              <div className="content-wrapper">
                <AppRoutes user={this.state.user} setUser={this.setUser} setFeatureId={this.setFeatureId} logged_contact={this.state.logged_contact} setLoggedContact={this.setLoggedContact} />
              </div>
              {footerComponent}
            </div>
          </div>
        </div>

      );

    }

    if (this.props.templete.template_api_name == "template_three" || this.props.templete.template_api_name == "template_four") {

      let no = parseInt(this.props.templete.id) - 1
      let class_name = "templete_" + no + " container-scroller";

      return (
        <div className={class_name}>
          <div className="horizontal-menu">
            {navbarComponent}
            <nav className="bottom-navbar">
              <div className="container-fluid">
                <ul className="nav page-navigation">
                  {sidebarComponent}
                </ul>
              </div>
            </nav>
          </div>
          <div className="container-fluid page-body-wrapper">
            <div className="main-panel">
              <div className="content-wrapper">
                <AppRoutes user={this.state.user} setUser={this.setUser} setFeatureId={this.setUser} logged_contact={this.state.logged_contact} setLoggedContact={this.setLoggedContact} />

              </div>
              {footerComponent}
            </div>
          </div>
        </div>

      );

    }

    if (this.props.templete.id == undefined) {
      //when he login in first
      if (localStorage.getItem('token')) {
        return (
          <div className="container-scroller">
            <div className="container-fluid page-body-wrapper ">
              <div className="main-panel">
                <div className="content-wrapper">
                </div>
              </div>
            </div>
          </div>
        );
      } else {

        //after logout
        return (
          <div className="container-scroller">
            { navbarComponent}
            <div className="container-fluid page-body-wrapper ">
              {sidebarComponent}
              <div className="main-panel">
                <div className="content-wrapper">
                  <AppRoutes user={this.state.user} setUser={this.setUser} logged_contact={this.state.logged_contact} setLoggedContact={this.setLoggedContact} />

                </div>
                {footerComponent}
              </div>
            </div>
          </div>
        );

      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    const { i18n } = this.props;
    const body = document.querySelector('body');
    if (this.props.location.pathname === '/layout/RtlLayout') {
      body.classList.add('rtl');
      i18n.changeLanguage('ar');
    }
    else {
      body.classList.remove('rtl')
      i18n.changeLanguage('en');
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/', '/login', '/forget-password', '/password-reset/'];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }

}

const getTheArray = (commonArray) => {
  return {
    type: 'USER_ADMIN_NAME',
    value: commonArray
  }
}

const getFeatureIdArray = (commonArray) => {
  return {
    type: 'FEATURE_ID',
    value: commonArray
  }
}

const getSettingArray = (commonArray) => {
  return {
    type: 'SETTING',
    value: commonArray
  }
}

const getZS_OptionArray = (commonArray) => {
  return {
    type: 'ZH_OPTIONS',
    value: commonArray
  }
}

const getTheLoggedArray = (commonArray) => {
  return {
    type: 'LOGGED_ADMIN_NAME',
    value: commonArray
  }
}

const getTempleteSetting = (commonArray) => {
  return {
    type: 'TEMPLETE_SETTING',
    value: commonArray
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    dispatchName: (array) => dispatch(getTheArray(array)),
    dispatchFeatureId: (array) => dispatch(getFeatureIdArray(array)),
    dispatcUser: (array) => dispatch(getTheLoggedArray(array)),
    dispatcTemplete: (array) => dispatch(getTempleteSetting(array)),
    dispatcSetting: (array) => dispatch(getSettingArray(array)),
    dispatcZh_option: (array) => dispatch(getZS_OptionArray(array))
  };
};

let mapStateToProps = (state) => {
  return {
    isLogged: state.isLogged,
    user: state.user_contact,
    logged_contact: state.logged_contact,
    templete: state.templete
  };
};

const UserPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPanels);

export default withTranslation()(withRouter(UserPanel));
