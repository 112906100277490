import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import Spinner from '../common/shared/Spinner';
import { PUBLIC_URL } from '../constants';

const Dashboard = lazy(() => import('../components/dashboard/Dashboard'));

// org profile
const MyProfile = lazy(() => import('../components/profile/MyProfile'));

// login
const Login = lazy(() => import('../components/auth/Login'));
// forget Password
const ForgetPassword = lazy(() => import('../components/auth/ForgetPassword'));
const ResetPassword = lazy(() => import('../components/auth/ResetPassword'));

// Task Page
const MyTask = lazy(() => import('../components/task/MyTask'));

// Projects Page
const Projects = lazy(() => import('../components/projects/Projects.js'));

// Add Project Page
const AddProject = lazy(() => import('../components/projects/AddProject.js'));

// Edit Project Page
const EditProject = lazy(() => import('../components/projects/EditProject.js'));

// Projects Page View
const ProjectView = lazy(() => import('../components/projects/ProjectView.js'));

// Milestone Page View
const MilestoneView = lazy(() => import('../components/projects/MilestoneView.js'));

// Reports Page
// const Report2 = lazy(() => import('../components/reports/Report2.js'));
const Reports = lazy(() => import('../components/reports/Reports.js'));

// Timesheet Page
const Timesheet = lazy(() => import('../components/timesheet/Timesheet.js'));

// Our Team Page
const OurTeam = lazy(() => import('../components/adminHR/OurTeam.js'));

// Our Team Page
const OurTeamView = lazy(() => import('../components/adminHR/OurTeamView.js'));

const OurTeamEdit = lazy(() => import('../components/adminHR/OurTeamEdit.js'));

// Attendance Page
const Attendance = lazy(() => import('../components/adminHR/Attendance.js'));

// Add Employee Page
const AddEmployee = lazy(() => import('../components/adminHR/AddEmployee.js')); 

// Leave Page
const Leave = lazy(() => import('../components/adminHR/Leave.js'));

// Role Page
const Role = lazy(() => import('../components/adminHR/Role.js'));

// Events Page
const Event = lazy(() => import('../components/adminHR/Event.js'));

// Message Page
const Message = lazy(() => import('../components/message/message.js'));

// Employee Role Permission Page
const EmployeeRolePermission = lazy(() => import('../components/adminHR/EmployeeRolePermission.js'));
// Clients Page
const Client = lazy(() => import('../components/client/Client.js'));

// Client View Page
const ClientView = lazy(() => import('../components/client/ClientView.js'));

// Calendar Page
const Calendar = lazy(() => import('../components/calendar/Calendar.js'));

// Help $ Support Page
const KnowledgeBase = lazy(() => import('../components/help_support/KnowledgeBase.js'));

const Articles = lazy(() => import('../components/help_support/Articles.js'));

const AddArticles = lazy(() => import('../components/help_support/AddArticles.js'));

const EditArticles = lazy(() => import('../components/help_support/EditArticles.js'));

const CategoryDetails = lazy(() => import('../components/help_support/CategoryDetails.js')); 

const ArticleDetails = lazy(() => import('../components/help_support/ArticleDetails.js'));

const Categories = lazy(() => import('../components/help_support/Categories.js'));


// Knowledge Base Page
// const KnowledgeBase = lazy(() => import('../components/knowledgebase/KnowledgeBase.js'));

// Empty Page
const EmptyPage = lazy(() => import('../components/dummy/EmptyPage'));

const testPage = lazy(() => import('../components/testPage'));

//Client Portal
// const ClientPortal = lazy(() => import('../components/ClientPortal/ClientPortal.js'));

class AppRoutes extends Component {

  render() {

    if (!localStorage.getItem('token')) {
      return (
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route exact path={`${PUBLIC_URL}/login`} component={() => <Login user={this.props.user} setUser={this.props.setUser} logged_contact={this.props.logged_contact} feature_id={this.props.feature_id} setLoggedContact={this.props.setLoggedContact} />} ></Route>
            <Route exact path={`${PUBLIC_URL}/forget-password`} component={ForgetPassword} />
            <Route exact path={`${PUBLIC_URL}/password-reset/:id`} component={ResetPassword} />
            {/* <Redirect to={`${PUBLIC_URL}/login`} /> */}
          </Switch>
        </Suspense>
      );
    } else {
      return (
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route exact path={`${PUBLIC_URL}/dashboard`} component={Dashboard} />
            <Route path={`${PUBLIC_URL}/my-profile`} component={MyProfile} />

            <Route path={`${PUBLIC_URL}/my-task`} component={MyTask} />
            <Route path={`${PUBLIC_URL}/projects`} component={Projects} />
            <Route path={`${PUBLIC_URL}/project/view/:id`} component={ProjectView} />
            <Route path={`${PUBLIC_URL}/milestone/view/:id`} component={MilestoneView} />
            <Route path={`${PUBLIC_URL}/add-project`} component={AddProject} />
            <Route path={`${PUBLIC_URL}/edit-project/:id`} component={EditProject} />
            {/* <Route path={`${PUBLIC_URL}/reports-copy`} component={Report2} /> */}
            <Route path={`${PUBLIC_URL}/reports`} component={Reports} />
            <Route path={`${PUBLIC_URL}/timesheet`} component={Timesheet} />
            <Route path={`${PUBLIC_URL}/client`} component={Client} />
            <Route path={`${PUBLIC_URL}/clients/view/:id`} component={ClientView} />
            <Route path={`${PUBLIC_URL}/calendar`} component={Calendar} />
            <Route path={`${PUBLIC_URL}/hrm/our-team`} component={OurTeam} />
            <Route path={`${PUBLIC_URL}/hrm/our-team-view/:id`} component={OurTeamView} />
            <Route path={`${PUBLIC_URL}/hrm/our-team-edit/:id`} component={OurTeamEdit} />
            <Route path={`${PUBLIC_URL}/hrm/addemployee`} component={AddEmployee} />
            <Route path={`${PUBLIC_URL}/hrm/attendance`} component={Attendance} />
            <Route path={`${PUBLIC_URL}/hrm/leave`} component={Leave} />
            <Route path={`${PUBLIC_URL}/hrm/role`} component={Role} />
            <Route path={`${PUBLIC_URL}/hrm/events`} component={Event} />
            <Route path={`${PUBLIC_URL}/hrm/message`} component={Message} />
            <Route path={`${PUBLIC_URL}/hrm/employeerolepermission/:id`} component={EmployeeRolePermission} />

            <Route path={`${PUBLIC_URL}/help/KnowledgeBase`} component={KnowledgeBase} />
            <Route path={`${PUBLIC_URL}/help/Articles`} component={Articles} />
            <Route path={`${PUBLIC_URL}/Add-Articles`} component={AddArticles} />
            <Route path={`${PUBLIC_URL}/edit-articles/:id`} component={EditArticles} />
            <Route path={`${PUBLIC_URL}/Details-Category/:id`} component={CategoryDetails} />
            <Route path={`${PUBLIC_URL}/Details-Article/:id`} component={ArticleDetails} />
            <Route path={`${PUBLIC_URL}/help/Categories`} component={Categories} />


            {/* <Route path={`${PUBLIC_URL}/knowledge-base`} component={KnowledgeBase} /> */}
            <Route path={`${PUBLIC_URL}/empty-page`} component={EmptyPage} />
            <Route path={`${PUBLIC_URL}/test-page`} component={testPage} />
            {/* <Route path={`${PUBLIC_URL}/Client-Portal`} component={ClientPortal} /> */}
            
            <Route exact path={`${PUBLIC_URL}/login`} component={() => <Login user={this.props.user} feature_id={this.props.feature_id} setUser={this.props.setUser} logged_contact={this.props.logged_contact} setLoggedContact={this.props.setLoggedContact} />} ></Route>
            <Redirect to={`${PUBLIC_URL}/login`} />

          </Switch>
        </Suspense>
      );
    }



  }
}

export default AppRoutes;
