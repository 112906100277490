
const initialState = {
    feature_id: []
  }
  
  
  const feature_idReducer = (state = initialState, action) => {
  
      switch (action.type) {
        case "FEATURE_ID":
          return action.value;
          break;
        default:
          return state;
          
      }
    }
  
    export default feature_idReducer;